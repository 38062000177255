<template>
  <v-calendar :attributes="attrs" disable-page-swipe is-expanded>
    <template #day-popover="{ day, dayTitle, attributes }">
      <div>
        <div class="text-xs text-gray-300 font-semibold text-center">
          {{ dayTitle }}
        </div>
        <popover-row
          v-for="attr in attributes"
          :key="attr.key"
          :attribute="attr"
        >
          <a :href="attr.customData.link" class="text-white">{{
            attr.customData.title
          }}</a>
        </popover-row>
      </div>
    </template>
  </v-calendar>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VCalendar from "v-calendar";
import PopoverRow from "v-calendar/lib/components/popover-row.umd.min";
Vue.use(VCalendar);
export default {
  name: "Calendar",
  components: {
    PopoverRow,
  },
  data() {
    return {
      attrs: [],
    };
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    getEvents() {
      axios.get("/wp-json/wp/v2/events/").then((response) => {
        const events = response.data.map((event) => {
          let eventDates;
          if (event.acf["実施日"]["開始"] && event.acf["実施日"]["終了"]) {
            eventDates = {
              start: new Date(event.acf["実施日"]["開始"]),
              end: new Date(event.acf["実施日"]["終了"]),
            };
          } else if (event.acf["実施日"]["開始"]) {
            eventDates = new Date(event.acf["実施日"]["開始"]);
          } else if (event.acf["実施日"]["終了"]) {
            eventDates = new Date(event.acf["実施日"]["終了"]);
          } else {
            eventDates = false;
          }
          return {
            key: "event",
            highlight: true,
            dates: eventDates,
            customData: {
              title: event.title.rendered,
              link: event.link,
            },
            popover: true,
          };
        });
        this.attrs = [...this.attrs, ...events];
        console.log(this.attrs);
        // attrs;
      });
    },
  },
};
</script>
