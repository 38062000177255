var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-calendar", {
    attrs: {
      attributes: _vm.attrs,
      "disable-page-swipe": "",
      "is-expanded": ""
    },
    scopedSlots: _vm._u([
      {
        key: "day-popover",
        fn: function(ref) {
          var day = ref.day
          var dayTitle = ref.dayTitle
          var attributes = ref.attributes
          return [
            _c(
              "div",
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-xs text-gray-300 font-semibold text-center"
                  },
                  [_vm._v("\n        " + _vm._s(dayTitle) + "\n      ")]
                ),
                _vm._v(" "),
                _vm._l(attributes, function(attr) {
                  return _c(
                    "popover-row",
                    { key: attr.key, attrs: { attribute: attr } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "text-white",
                          attrs: { href: attr.customData.link }
                        },
                        [_vm._v(_vm._s(attr.customData.title))]
                      )
                    ]
                  )
                })
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }